.post {
  background: $post-background;
  display: flex;
  flex-direction: column;

  .thumbnail {
    display: block;
    flex: none;
    text-decoration: none;
    outline: none;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 30px;

    .title {
      flex: 1 0 auto;
      margin-bottom: 30px;
      font-weight: normal;
      font-size: 36px;
    }

    .meta {
      display: flex;
      font-size: 12px;
      color: $light-text-color;
      text-transform: uppercase;

      .category {
        margin-right: 10px;
        font-weight: bold;

        a {
          &:hover {
            text-decoration: underline;
          }
        }

        &:after {
          font-weight: normal;
          margin-left: 10px;
          content: '|';
        }
      }
    }
  }
}

.download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;

  & > * {
    width: 100%;
  }

  .thumbnail {
    display: block;
    margin-bottom: 20px;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .info {
    color: #fff;
    text-align: center;

    .title {
      font-family: "Bree Serif", Serif;
      font-size: 18px;
      font-weight: normal;
    }

    .description {
      margin-top: 15px;
    }

    a.button {
      display: inline-block;
      background: none;
      padding: 12px 45px;
      margin-top: 30px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 50px;
      font-weight: normal;
      font-size: 16px;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background: #fff;
      }
    }

    &.no-thumbnail {
      .title {
        font-size: 32px;
      }

      .description {
        font-size: 18px;
      }
    }
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;
    padding: 50px;

    .thumbnail {
      margin-bottom: 0px;
      margin-right: 30px;
    }
  }
}

@media (max-width: 768px) {
  .download {
    &.horizontal {
      flex-direction: column;
      align-items: flex-start;

      .thumbnail {
        margin-right: 0px;
        margin-bottom: 30px;
      }
    }
  }
}
