$container-width: 1140px;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background: $background;

  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $text-color;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
}

a {
  color: inherit;
  text-decoration: none;
}

img, iframe {
  vertical-align: bottom;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.container {
  width: 100%;
  max-width: $container-width;
  padding: 0px 15px;
  margin: 0 auto;

  & > .row {
    display: flex;
  }
}

header.header {
  background: $header;
  padding: 20px 0px;

  .row {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .logo {
    display: flex;
    align-items: center;

    .image {
      margin-right: 5px;

      img {
        max-width: 131px;
        width: 100%;
      }
    }

    .name {
      background: #fff;
      padding: 5px;
      font-size: 12px;
      color: $text-color;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  #contact-menu {
    list-style: none;

    &.list {
      li {
        a {
          display: inline-block;
          background: #18FEB9;
          padding: 10px 30px;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          border-radius: 30px;
          color: #fff;
          text-decoration: none;
          transition: .3s;

          &:hover {
            background: #6C63FF;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  header.header {
    .row {
      display: block;
      text-align: center;
    }

    .logo {
      justify-content: center;
      margin-bottom: 15px;

      .name {
        display: none;
      }
    }
  }
}

nav.nav {
  padding: 30px 0px;

  .row {
    justify-content: flex-end;
  }

  .list {
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;

      a {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}


@media (max-width: 768px) {
  nav.nav {
    padding: 15px 0px;

    .list {
      text-align: center;

      li {
        a {
          display: block;
          padding: 8px 0px;
        }
      }
    }
  }
}

section.thematics {
  margin-bottom: 80px;

  h3.title {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .list {
    display: flex;
    justify-content: center;
    flex: 1;
    list-style: none;
    margin: 0px -15px;

    li {
      flex: 0 0 auto;
      flex-basis: 25%;
      max-width: 25%;
      padding: 0px 15px;
      
      a {
        background: $header;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        text-align: center;
        color: #fff;
        padding: 40px;
        border-radius: 20px;
        height: 100%;

        &:after {
          display: block;
          width: 100%;
          padding: 16px 10px;
          margin-top: 30px;
          border: 1px solid #fff;
          border-radius: 50px;
          text-align: center;
          font-size: 14px;
          content: 'Voir les articles';
        }
      }
    }
  }
}

@media (max-width: 768px) {
  section.thematics {
    .list {
      flex-wrap: wrap;

      li {
        flex: 0 0 auto;
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: 30px;

        a {
          padding: 30px;

          &:after {
            content: 'Découvrir'
          }
        }
      }
    }
  }
}

footer.footer {
  background: $header;
  color: #fff;
  padding: 40px 0px;

  .container {
    .row {
      &:last-child {
        flex: 0 1 auto;
        margin: 0 -15px;
        justify-content: center;

        & > * {
          flex: 0 0 auto;
          flex-basis: 25%;
          max-width: 25%;
          padding: 0px 15px;
        }
      }
    }
  }

  .copyright {
    flex: 1;
    text-align: center;
    margin-bottom: 30px;

    .logo {
      margin-bottom: 15px;

      img {
        width: 100%;
        max-width: 226px;
      }
    }

    .socials {
      font-size: 24px;
    }
  }

  .footer-menu {
    text-align: center;

    h4.title {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .menu {
      list-style: none;
      line-height: 22px;
    }
  }
}

@media (max-width: 768px) {
  footer.footer {
    .container {
      .row {
        &:last-child {
          flex: 0 1 auto;
          flex-wrap: wrap;
          margin: 0 -15px;
          justify-content: center;

          & > * {
            flex: 0 0 auto;
            flex-basis: 50%;
            max-width: 50%;
            padding: 0px 15px;
          }
        }
      }
    }
  }
}
