section.blog {
  margin-bottom: 80px;

  .row {
    flex: 0 1 auto;
    margin: 0 -15px;
    margin-bottom: 30px;

    & > * {
      flex: 0 0 auto;
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
      padding: 0px 15px;

      &.two-thirds {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .entry {
    & > * {
      height: 100%;
    }
  }

  .newsfeed {
    & > * {
      height: auto;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    & > .title {
      font-size: 12px;
      color: $very-light-text-color;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    
    .post {
      .thumbnail {
        display: none;
      }

      .info {
        .title {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  section.blog {
    .row {
      flex-wrap: wrap;

      & > * {
        flex-basis: 100%;
        max-width: 100%;

        &.two-thirds {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }

    .entry {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .newsfeed {
      background: $header;
      padding: 30px;
    }
  }
}

section.pagination {
  margin-top: -20px;
  margin-bottom: 80px;

  .row {
    justify-content: center;
  }

  nav.pagination {
    .nav-links {
      display: flex;
      justify-content: center;
      font-size: 16px;

      & > * {
        background: $post-background;
        padding: 10px 15px;
        margin-right: 10px;
        border-radius: 4px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          background: darken($post-background, 5%);
        }
      }

      .current {
        background: $header;
        color: #fff;
      }
    }
  }
}