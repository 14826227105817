section.single-download {
  margin: 80px 0px;

  .row {
    flex: 0 1 auto;
    margin: 0 -15px;
    margin-bottom: 80px;

    & > * {
      flex: 0 0 auto;
      padding: 0px 15px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .content {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    font-size: 18px;
    line-height: 28px;

    h1.title {
      margin: 0px;
      margin-bottom: 30px;
      font-weight: normal;
      font-size: 36px;
      line-height: 36px;
    }

    .thumbnail {
      display: block;
      text-align: center;
      margin-bottom: 30px;
      text-decoration: none;
      outline: none;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    h2 {
      color: $title-color;
      font-size: 18px;
      margin-bottom: 30px;
    }

    .text {
      & > p {
        margin-bottom: 30px;
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
  
        a { 
          color: $title-color;
    
          &:hover {
            text-decoration: underline;
          }
        }
      }
  
      & > ul {
        list-style-position: inside;
        padding-left: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .form {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    text-align: center;

    h3.title {
      font-family: "Bree Serif", serif;
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 40px;
    }

    form {
      max-width: 80%;
      margin: 0 auto;

      .error {
        background: #FFCDD2;
        color: #B71C1C;
        padding: 10px 15px;
        border-radius: 4px;
        margin-bottom: 15px;
      }

      .small {
        color: $light-text-color;
      }

      .group {
        display: flex;
        width: 100%;
        margin-bottom: 15px;

        input {
          width: 100%;
          margin-right: 15px;
          padding: 10px 15px;
          border: 1px solid $form-border-color;
          border-radius: 4px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }

      input[name="address"] {
        display: none;
        visibility: hidden;
        opacity: 0;
      }

      button[type="submit"] {
        background: #fff;
        padding: 12px 45px;
        margin-top: 30px;
        border: 1px solid $header;
        color: $header;
        border-radius: 50px;
        font-size: 16px;
        cursor: pointer;
        transition: .2s;

        &:hover {
          background: $header;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  section.single-download {
    .container {
      .row {
        &:first-child {
          flex-wrap: wrap;
        }
      }
    }

    .content {
      flex-basis: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .form {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

section.confirm-download {
  background: darken(#fff, 2%);
  padding: 100px 0px;

  .thanks {
    background: #fff;
    flex: 1;
    padding: 80px;
    text-align: center;
    box-shadow: 0px 10px 50px rgba(#000, .1);

    .icon {
      display: inline-block;
      font-size: 72px;
      line-height: 72px;
      transform: rotate(-15deg);
      transition: .3s;
      margin-bottom: 15px;

      &:hover {
        transform: rotate(5deg);
      }
    }

    .title {
      margin: 0px;
      margin-bottom: 30px;
      font-family: "Bree Serif", serif;
      font-weight: normal;
      font-size: 24px;
    }

    a.button {
      display: inline-block;
      background: #fff;
      padding: 12px 45px;
      border: 1px solid $header;
      color: $header;
      border-radius: 50px;
      font-size: 16px;
      cursor: pointer;
      transition: .2s;
      margin-bottom: 80px;

      &:hover {
        background: $header;
        color: #fff;
      }
    }

    .small {
      color: $light-text-color;
      line-height: 24px;

      a {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 768px) {
  section.confirm-download {
    padding: 30px 0px;

    .thanks {
      padding: 40px;

      a.button {
        padding: 12px 40px;
      }
      
      br {
        display: none;
      }
    }
  }
}
