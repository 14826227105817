section.single {
  margin-bottom: 80px;

  .row {
    flex: 0 1 auto;
    margin: 0 -15px;
    margin-bottom: 80px;

    & > * {
      flex: 0 0 auto;
      padding: 0px 15px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &.page {
    .post-description {
      flex-basis: 100%;
      max-width: 100%;
    }

    .content {
      .wpcf7 {
        background: $post-background;
        padding: 30px;

        form {
          input, textarea {
            width: 100%;
            padding: 10px 15px;
            border: 1px solid $form-border-color;
            border-radius: 4px;
            margin-bottom: 15px;
          }
    
          input[type="submit"] {
            background: $post-background;
            padding: 12px 45px;
            margin-top: 15px;
            margin-bottom: 0px;
            border: 1px solid $header;
            color: $header;
            border-radius: 50px;
            font-size: 16px;
            cursor: pointer;
            transition: .2s;
    
            &:hover {
              background: $header;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .post-description {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;

    .article {
      .meta {
        display: flex;
        font-size: 12px;
        color: $light-text-color;
        text-transform: uppercase;
        margin-bottom: 30px;

        .category {
          margin-right: 10px;
          font-weight: bold;

          &:after {
            font-weight: normal;
            margin-left: 10px;
            content: '|';
          }
        }
      }

      .title {
        margin: 0px;
        margin-bottom: 30px;
        font-weight: normal;
        font-size: 36px;
      }
    }

    .author {
      display: flex;
      align-items: center;

      .avatar {
        flex: 0 0 auto;
        margin-right: 15px;
        border-radius: 50%;
        width: 134px;
        height: 134px;
        overflow: hidden;

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }

      .meta {
        .job {
          margin-top: 5px;
          color: $light-text-color;
        }

        .socials {
          display: flex;
          margin-top: 15px;

          a {
            display: block;
            background: $header;
            width: 32px;
            height: 32px;
            color: #fff;
            margin-right: 10px;
            border-radius: 50px;
            line-height: 32px;
            text-align: center;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  .post-thumbnail {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .content {
    margin-left: 16.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    font-size: 18px;
    line-height: 28px;

    h2 {
      margin-bottom: 10px;
    }

    & > p {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      a { 
        color: $title-color;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > blockquote {
      margin-bottom: 30px;
    }

    & > ul {
      list-style-position: inside;
      padding-left: 30px;
      margin-bottom: 30px;
    }

    & > .wp-caption {
      width: 100% !important;
      margin-bottom: 30px;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .wp-caption-text {
        text-align: center;
        font-size: 14px;
        opacity: .7;
      }
    }

    .relationship {
      margin-top: 80px;
      margin-bottom: 80px;

      &.inline {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  section.single {
    .container {
      .row {
        &:first-child {
          flex-wrap: wrap;
        }
      }
    }

    &.page {
      .content {
        .wpcf7 {
          padding: 15px;
        }
      }
    }

    .post-description {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 30px;

      .article {
        .meta {
          margin-bottom: 15px;
        }
      }

      .author {
        .avatar {
          width: 64px;
          height: 64px;
        }
      }
    }

    .post-thumbnail {
      flex-basis: 100%;
      max-width: 100%;
    }

    .content {
      margin-left: 0px;
      flex-basis: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 26px;
    }
  }
}
